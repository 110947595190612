import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth/auth.service";
import { CommonService } from "../../services/common/common.service";
import { FriendService } from "../../services/friend/friend.service";
import { UserService } from "../../services/user/user.service";

import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

declare let $: any;

@Component({
  selector: "app-friend-form",
  templateUrl: "./friend-form.page.html",
  styleUrls: ["./friend-form.page.scss"],
  providers: [FriendService, CommonService, UserService, AuthService],
})
export class FriendFormPage implements OnInit {
  email: any = "";

  @Input() item: any = {};

  profile: any = {};

  public isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private friendService: FriendService,
    private commonService: CommonService,
    private userService: UserService,
    private auth: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.profile = this.auth.getLoggedUser();
  }

  onChangeDateTime($event) {
    this.item.start_date = $event.detail.value;
  }

  dismiss(data = null) {
    this.modalCtrl.dismiss(data);
  }

  private saveUserInit() {
    window.localStorage.setItem("IS_INIT", "active");
  }

  save() {
    this.isLoading = true;

    this.userService
      .list({
        email: this.email,
      })
      .subscribe((resp) => {
        if (!resp.total) {
          this.commonService.showMessage(
            this.translate.instant(
              "Cannot find your email address in system. Please try again!"
            )
          );
          this.isLoading = false;

          return;
        }

        this.checkFriendExist(resp.data[0]);
      });
  }

  private checkFriendExist(friend) {
    this.friendService
      .list({
        user: this.profile.id,
        friend: friend.id,
      })
      .subscribe((resp) => {
        if (resp.total) {
          this.commonService.showMessage(
            this.translate.instant(
              "Your friend is existed in your friend list."
            )
          );

          this.isLoading = false;
          return;
        }

        this.saveFriend(friend);
      });
  }

  private saveFriend(friend: any) {
    this.item.user = this.profile.id;
    this.item.friend = friend.id;

    this.friendService.save(this.item).subscribe(
      (resp) => {
        this.isLoading = false;
        this.saveUserInit();
        this.dismiss(resp);
      },
      (err) => {
        this.isLoading = false;
        this.commonService.showMessage(err.error.message);
      }
    );
  }
}
