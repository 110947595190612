import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-list',
  templateUrl: './loading-list.component.html',
  styleUrls: ['./loading-list.component.scss'],
})
export class LoadingListComponent implements OnInit {
  @Input() items = [1, 2, 3];
  constructor() {}

  ngOnInit() {}

}
