const PERSONAL_HABITS = [
  {
    id: 1,
    taskType: 'PERSONAL',
    title: "Morning Meditation",
    description: "Practice mindfulness for 10 minutes every morning.",
    icon: "mdi-meditation",
    thumbnail: "assets/images/Morning-Meditation.png",
  },
  {
    id: 2,
    taskType: 'PERSONAL',
    title: "Daily Exercise",
    description: "Engage in 30 minutes of physical activity each day.",
    icon: "mdi-dumbbell",
    thumbnail: "assets/images/Daily-Exercise.jpeg",
  },
  {
    id: 3,
    taskType: 'PERSONAL',
    title: "Read a Book",
    description: "Read at least one chapter of a book every day.",
    icon: "mdi-book-open-page-variant",
    thumbnail: "assets/images/Read-a-Book.jpeg",
  },
  {
    id: 4,
    taskType: 'PERSONAL',
    title: "Learn a New Skill",
    description: "Dedicate time to learning a new skill or hobby each week.",
    icon: "mdi-lightbulb-on",
    thumbnail: "assets/images/Learn-a-New-Skill.jpeg",
  },
  {
    id: 5,
    taskType: 'PERSONAL',
    title: "Gratitude Journal",
    description: "Write down three things you're grateful for every night.",
    icon: "mdi-pencil",
    thumbnail: "assets/images/Gratitude-Journal.jpeg",
  },
  {
    id: 6,
    taskType: 'PERSONAL',
    title: "Drink Water",
    description: "Consume at least 8 glasses of water throughout the day.",
    icon: "mdi-cup-water",
    thumbnail: "assets/images/Drink-Water.png",
  },
  {
    id: 7,
    taskType: 'PERSONAL',
    title: "Healthy Snacking",
    description: "Choose nutritious snacks over processed ones.",
    icon: "mdi-carrot",
    thumbnail: "assets/images/Healthy-Snacking.png",
  },
  {
    id: 8,
    taskType: 'PERSONAL',
    title: "Mindful Breathing",
    description: "Practice deep breathing exercises for 5 minutes daily.",
    icon: "mdi-air-filter",
    thumbnail: "assets/images/Mindful-Breathing.jpeg",
  },
  {
    id: 9,
    taskType: 'PERSONAL',
    title: "Daily Journaling",
    description: "Write down your thoughts and experiences in a journal.",
    icon: "mdi-file-document-edit",
    thumbnail: "assets/images/Daily-Journaling.jpeg",
  },
  {
    id: 10,
    taskType: 'PERSONAL',
    title: "Limit Screen Time",
    description: "Set a daily limit on your screen time to reduce digital fatigue.",
    icon: "mdi-monitor",
    thumbnail: "assets/images/Limit-Screen-Time.png",
  },
  {
    id: 11,
    taskType: 'PERSONAL',
    title: "Evening Stretching",
    description: "Stretch your body for 10 minutes before bedtime.",
    icon: "mdi-human-handsup",
    thumbnail: "assets/images/Evening-Stretching.png",
  },
  {
    id: 12,
    taskType: 'PERSONAL',
    title: "Healthy Breakfast",
    description: "Start your day with a nutritious breakfast.",
    icon: "mdi-food-apple",
    thumbnail: "assets/images/Healthy-Breakfast.png",
  },
  {
    id: 13,
    taskType: 'PERSONAL',
    title: "Mindfulness Walk",
    description: "Take a mindful walk in nature for 15 minutes.",
    icon: "mdi-walk",
    thumbnail: "assets/images/Mindful-Breathing.jpeg",
  },
  {
    id: 14,
    taskType: 'PERSONAL',
    title: "Digital Detox Day",
    description: "Designate one day a week for a digital detox.",
    icon: "mdi-cellphone-off",
    thumbnail: "assets/images/Digital-Detox-Day.jpeg",
  },
  {
    id: 16,
    taskType: 'PERSONAL',
    title: "Creative Time",
    description: "Engage in a creative activity for 20 minutes daily.",
    icon: "mdi-palette",
    thumbnail: "assets/images/Creative-Time.jpeg",
  },
  {
    id: 17,
    taskType: 'PERSONAL',
    title: "Healthy Lunch Choices",
    description: "Opt for healthy lunch options rich in nutrients.",
    icon: "mdi-food",
    thumbnail: "assets/images/Healthy-Lunch-Choices.png",
  },
  {
    id: 18,
    taskType: 'PERSONAL',
    title: "Nightly Reflection",
    description: "Reflect on your day and set goals for tomorrow.",
    icon: "mdi-comment-quote",
    thumbnail: "assets/images/Nightly-Reflection.jpeg",
  },
  {
    id: 19,
    taskType: 'PERSONAL',
    title: "Practice Mindful Eating",
    description: "Eat your meals slowly and savor each bite mindfully.",
    icon: "mdi-silverware-fork-knife",
    thumbnail: "assets/images/Practice-Mindful-Eating.jpeg",
  },
  {
    id: 20,
    taskType: 'PERSONAL',
    title: "Connect with Loved Ones",
    description: "Regularly connect with friends or family members.",
    icon: "mdi-account-heart",
    thumbnail: "assets/images/Connect-with-Loved-Ones.png",
  },
  {
    id: 0,
    taskType: 'PERSONAL',
    title: "Other",
    description: "Take control of your life by managing your habits.",
    icon: "mdi-account-heart",
    thumbnail: "assets/images/Take-control.jpeg",
  },
];


export default PERSONAL_HABITS;
