import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-amount',
  templateUrl: './transaction-amount.component.html',
  styleUrls: ['./transaction-amount.component.scss'],
})
export class TransactionAmountComponent implements OnInit {

  @Input() item: any = {};

  constructor() { }

  ngOnInit() {}

}
