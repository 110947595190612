import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as moment from "moment";
import { sumBy } from "lodash";
import uuidv4 from "src/app/utils/uuid";
import TRANSACTION_CATEGORIES from "../../constants/TRANSACTION_CATEGORIES";
import { TransactionService } from "../../services/transaction/transaction.service";
import { MonthService } from "../../services/month/month.service";
import { AuthService } from "src/app/services/auth/auth.service";
import TRANSACTION_TYPES from "../../constants/TRANSACTION_TYPES";

declare let Highcharts: any;

@Component({
  selector: "app-transaction-summary-chart",
  templateUrl: "./transaction-summary-chart.component.html",
  styleUrls: ["./transaction-summary-chart.component.scss"],
})
export class TransactionSummaryChartComponent implements OnInit {
  @Input() items: any = [];
  @Input() prevItems: any = [];

  expenseChartId = uuidv4();

  constructor(
    private transactionService: TransactionService,
    private monthService: MonthService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.getTransactions();
  }

  loaded = false;
  private getTransactions() {
    this.loaded = false;

    this.transactionService
      .list({
        $sort: { transactionDate: -1, createdAt: -1 },
        transactionDate: {
          $gte: moment().startOf("month").format(),
          $lte: moment().endOf("month").format(),
        },
        $limit: 99999,
      })
      .subscribe(
        (resp) => {
          this.loaded = true;

          this.items = resp.data;
          setTimeout(() => this.drawExpenseChart(), 400);
        },
        () => {
          this.loaded = true;
        }
      );
  }

  drawExpenseChart() {
    // Data retrieved from https://netmarketshare.com/
    // Build the chart

    const categories = TRANSACTION_TYPES;
    const seriesData = [];

    let index = 0;
    for (let category of categories) {
      const sumData = sumBy(this.items, (o) => {
        if (o.type === category.value) {
          return o.amount;
        }

        return 0;
      });

      if (sumData === 0) {
        continue;
      }

      seriesData.push({
        name: category.title,
        y: sumData,
        color: category.color,
        sliced: !index,
        selected: !index,
      });

      index++;
    }

    Highcharts.chart(this.expenseChartId, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: null,
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },
      series: [
        {
          name: "Expenses",
          colorByPoint: true,
          data: seriesData,
        },
      ],
    });
  }

  sumPrevDataWithType(type = "") {
    return sumBy(this.prevItems, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumDataWithType(type = "") {
    return sumBy(this.items, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumNetOverall() {
    return this.sumDataWithType("income") - this.sumDataWithType("expense");
  }
}
