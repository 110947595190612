import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavController } from "@ionic/angular";
import { NotificationService } from "../../services/notification/notification.service";

import _ from "lodash";

import * as moment from "moment";

@Component({
  selector: "app-notification-item",
  templateUrl: "./notification-item.component.html",
  styleUrls: ["./notification-item.component.scss"],
})
export class NotificationItemComponent implements OnInit {
  @Input() active = false;
  @Input() item = null;

  @Output() delete: any = new EventEmitter();
  @Output() update: any = new EventEmitter();

  constructor(
    private navCtrl: NavController,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  async openDetail() {
    this.item.status = 'seen';
    this.navCtrl.navigateForward(['/notification-detail', {id: this.item.id}]);
    // const modal: any = await this.modalCtrl.create({
    //   component: NotificationDetailPage,
    //   componentProps: {
    //     item: this.item,
    //   },
    // });

    // modal.onDidDismiss().then(($event: any) => {
    //   if (!$event.data) {
    //     return;
    //   }

    //   const { type, item } = $event.data;
    //   switch (type) {
    //     case "DELETE":
    //       this.delete.emit(item);
    //       break;
    //     case "UPDATE":
    //       this.update.emit(item);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    // return await modal.present();
  }

  isOverDate() {
    if (this.item.status === "completed") {
      return false;
    }
    const $startDate = moment(this.item.start_date).endOf("day");
    const $today = moment().startOf("day");
    return $startDate.isBefore($today);
  }

  isSeen() {
    return this.item.status === "seen";
  }

  onChange() {
    this.item.status = this.item.status === "new" ? "completed" : "new";

    this.notificationService
      .save({ id: this.item.id, status: this.item.status })
      .subscribe();
  }

  getExcerpt(str) {
    if (!str) {
      return null;
    }

    return str.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 256) + '...';
  }
}
