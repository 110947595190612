const LABELS = [
  {
    color: "danger",
    code: "MAIN",
    title: "Main responsibilities",
    description:
      "These are daily tasks that play a key role in each person's job. These are tasks that must be done every day",
  },
  {
    color: "warning",
    code: "PERSONAL",
    title: "Personal development",
    description:
      "This group includes activities and projects that each person feels are valuable, meaningful and create personal satisfaction but are not in the essential group.",
  },
  {
    color: "primary",
    code: "PEOPLE",
    title: "People management",
    description:
      "Do you report directly to anyone? Do you coordinate with colleagues? Or commanding a group? If so, the time you spend managing people (including your team, peers, and superiors) should also be tracked.",
  },
  {
    color: "dark",
    code: "ADMIN",
    title: "Administrative work",
    description:
      "These are trivial but necessary tasks that you have to do every day such as: checking emails, schedules, expense reports, signing invoices...",
  },
  {
    color: "danger",
    code: "UNEXPECTED",
    title: "Unexpected work",
    description:
      "Includes incidents that disrupt work such as: unexpected problems, unexpected meetings - this group needs special monitoring because these are works that hinder time planning. time.",
  },
  {
    color: "success",
    code: "REST",
    title: "Rest time",
    description: "Represents time for rest, relaxation and regeneration after stressful work",
  },
  {
    color: "purple",
    code: "FAMILY",
    title: "Family",
    description: "Represents work or tasks related to family or personal activities outside of work",
  },
  {
    color: "medium",
    code: "OTHER",
    title: "Others",
    description: "Include small tasks, tasks that are not high priority, or goals that are not important or necessary to track carefully",
  },
];

export default LABELS;
