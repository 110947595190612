import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth/auth.service";
import { CommonService } from "../../services/common/common.service";
import { ProjectService } from "../../services/project/project.service";
import { UserService } from "../../services/user/user.service";

import * as moment from "moment";

declare let $: any;

@Component({
  selector: "app-project-form",
  templateUrl: "./project-form.page.html",
  styleUrls: ["./project-form.page.scss"],
  providers: [ProjectService, CommonService, UserService, AuthService],
})
export class ProjectFormPage implements OnInit {
  @Input() item: any = {
    title: "",
    description: "",
    type: "work",
    status: "new",
    pomodoro: 1,
    start_date: null,
    goals: [],
  };

  profile: any = {};

  public isLoading: boolean = false;
  public types = ["work", "personal", "family", "other"];
  public pomodoros = [1, 2, 3, 4, 5];

  constructor(
    private modalCtrl: ModalController,
    private projectService: ProjectService,
    private commonService: CommonService,
    private userService: UserService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.profile = this.auth.getLoggedUser();
    
    if (!this.item.start_date) {
      this.item.start_date = moment().format('YYYY-MM-DD');
    }

    setTimeout(() => {
      $(() => {
        $(".input-flatpickr").flatpickr({
          defaultDate: this.item.start_date,
        });
      });
    }, 100);
  }

  onChangeDateTime($event) {
    this.item.start_date = $event.detail.value;
  }

  dismiss(data = null) {
    this.modalCtrl.dismiss(data);
  }

  private saveUserInit() {
    window.localStorage.setItem('IS_INIT', 'active');
  }

  save() {
    this.isLoading = true;

    if (!this.item.thumbnail) {
      this.item.thumbnail = `assets/images/project-${this.item.type}.png`;
    }

    this.projectService.save(this.item).subscribe(
      (resp) => {
        this.isLoading = false;
        this.saveUserInit();
        this.dismiss(resp);

      },
      (err) => {
        this.isLoading = false;
        this.commonService.showMessage(err.error.message);
      }
    );
  }
}
