import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavController } from "@ionic/angular";
import { GoalService } from "../../services/goal/goal.service";

import _ from "lodash";

import * as moment from "moment";

@Component({
  selector: "app-goal-item",
  templateUrl: "./goal-item.component.html",
  styleUrls: ["./goal-item.component.scss"],
})
export class GoalItemComponent implements OnInit {
  @Input() active = false;
  @Input() item = null;

  @Output() delete: any = new EventEmitter();
  @Output() update: any = new EventEmitter();

  constructor(
    private navCtrl: NavController,
    private goalService: GoalService
  ) {}

  ngOnInit() {}

  async openDetail() {
    this.navCtrl.navigateForward(['/goal-detail', {id: this.item.id}]);
    // const modal: any = await this.modalCtrl.create({
    //   component: GoalDetailPage,
    //   componentProps: {
    //     item: this.item,
    //   },
    // });

    // modal.onDidDismiss().then(($event: any) => {
    //   if (!$event.data) {
    //     return;
    //   }

    //   const { type, item } = $event.data;
    //   switch (type) {
    //     case "DELETE":
    //       this.delete.emit(item);
    //       break;
    //     case "UPDATE":
    //       this.update.emit(item);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    // return await modal.present();
  }

  isOverDate() {
    if (this.item.status === "completed") {
      return false;
    }
    const $startDate = moment(this.item.start_date).endOf("day");
    const $today = moment().startOf("day");
    return $startDate.isBefore($today);
  }

  isActive() {
    return this.item.status === "completed";
  }

  onChange() {
    this.item.status = this.item.status === "new" ? "completed" : "new";

    this.goalService
      .save({ id: this.item.id, status: this.item.status })
      .subscribe();
  }

  getPercentage() {
    return this.goalService.percentage(this.item);
  }
}
