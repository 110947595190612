import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as moment from "moment";
import { sumBy } from "lodash";
import uuidv4 from "src/app/utils/uuid";
import TRANSACTION_CATEGORIES from "../../constants/TRANSACTION_CATEGORIES";

declare let Highcharts: any;

@Component({
  selector: "app-transaction-summary",
  templateUrl: "./transaction-summary.component.html",
  styleUrls: ["./transaction-summary.component.scss"],
})
export class TransactionSummaryComponent implements OnInit, OnChanges {
  @Input() month: any = {};
  @Input() items: any = [];
  @Input() prevItems: any = [];

  expenseChartId = uuidv4();
  trendlineChartId = uuidv4();

  constructor() {}

  ngOnInit() {
    // setTimeout(() => this.drawExpenseChart(), 1000);
    // setTimeout(() => this.drawTrendlineChart(), 1000);
  }

  ngOnChanges() {
    setTimeout(() => this.drawExpenseChart(), 400);
    setTimeout(() => this.drawTrendlineChart(), 400);
  }

  drawExpenseChart() {
    // Data retrieved from https://netmarketshare.com/
    // Build the chart

    const categories = TRANSACTION_CATEGORIES;
    const seriesData = [];

    let index = 0;
    for (let category of categories) {
      const sumData = sumBy(this.items, (o) => {
        if (o.category === category.value && o.type === "expense") {
          return o.amount;
        }

        return 0;
      });

      if (sumData === 0) {
        continue;
      }

      seriesData.push({
        name: category.title,
        y: sumData,
        sliced: !index,
        selected: !index,
      });

      index++;
    }

    Highcharts.chart(this.expenseChartId, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: null,
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        itemMarginTop: 10,
        itemMarginBottom: 10,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Expenses",
          colorByPoint: true,
          data: seriesData,
        },
      ],
    });
  }

  drawTrendlineChart() {
    const incomeData = [];
    const expenseData = [];

    const categories = [];
    for (
      let $date = moment().startOf("month");
      $date.isSameOrBefore(moment().endOf("months"));
      $date.add(1, "weeks")
    ) {
      const $checkedStart = moment($date);
      const $checkedEnd = moment($date).add(1, "week");

      categories.push($checkedStart.format("DD MMM YYYY"));

      const sumIncomeData = sumBy(this.items, (o) => {
        const $transactionDate = moment(o.transactionDate);

        if (
          $transactionDate.isSameOrAfter($checkedStart) &&
          $transactionDate.isBefore($checkedEnd) &&
          o.type === "income"
        ) {
          return o.amount;
        }

        return 0;
      });

      const sumExpenseData = sumBy(this.items, (o) => {
        const $transactionDate = moment(o.transactionDate);
        const $checkedStart = moment($date);
        const $checkedEnd = moment($date).add(7, "days");

        if (
          $transactionDate.isSameOrAfter($checkedStart) &&
          $transactionDate.isBefore($checkedEnd) &&
          o.type === "expense"
        ) {
          return o.amount;
        }

        return 0;
      });

      incomeData.push(sumIncomeData);
      expenseData.push(sumExpenseData);
    }

    Highcharts.chart(this.trendlineChartId, {
      chart: {
        type: "column",
      },
      title: null,
      subtitle: null,
      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: null,
      },
      tooltip: {},
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series: [
        {
          name: "Income",
          data: incomeData,
        },
        {
          name: "Expense",
          data: expenseData,
        },
      ],
    });
  }

  sumPrevDataWithType(type = "") {
    return sumBy(this.prevItems, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumDataWithType(type = "") {
    return sumBy(this.items, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumNetOverall() {
    return this.sumDataWithType('income') - this.sumDataWithType('expense');
  }
}
