import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-segments',
  templateUrl: './project-segments.component.html',
  styleUrls: ['./project-segments.component.scss'],
})
export class ProjectSegmentsComponent implements OnInit {
  @Input() projectId: any = null; 
  @Input() tabName: any = 'overral'; 

  constructor() {}

  ngOnInit() {}

}
