import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public http: HttpClient) {
  }

  list(inParams: any = {}): any {

    const params = {...inParams};

    if (params.$page) {
      params.$skip = (params.$page - 1) * (params.$limit);
    }

    if (params.$search) {
      params.$or = {
        fullname: {$like: `%${params.$search}%`},
        email: {$like: `%${params.$search}%`},
      };
      delete params.$search;
    }
    delete params.$page;

    const query = $.param(params);
    const url = `${environment.apiUrl}/users?${query}`;
    return this.http.get(url);
  }

  save(model): any {
    if (!model.password) {
      delete model.password;
    }

    if (model.id) {
      const updateUrl = `${environment.apiUrl}/users/${model.id}`;
      return this.http.patch(updateUrl, model);
    }

    delete model.id;
    const url = `${environment.apiUrl}/users`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/users/${id}`;
    const params: any = {
      // $populate: ["author", "school"],
    };

    return this.http.get(url, {params});
  }

  delete(model): any {
    const url = `${environment.apiUrl}/users/${model.id}`;
    return this.http.delete(url);
  }
}
