import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

function getDefaultRouting() {}

const routes: Routes = [
  {
    path: "",
    redirectTo: window.localStorage.getItem("accessToken") ? "home" : "login",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "welcome",
    loadChildren: () =>
      import("./pages/welcome/welcome.module").then((m) => m.WelcomePageModule),
  },
  {
    path: "start",
    loadChildren: () =>
      import("./pages/start/start.module").then((m) => m.StartPageModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/auth/register/register.module").then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "profile-info",
    loadChildren: () =>
      import("./pages/profile/profile-info/profile-info.module").then(
        (m) => m.ProfileInfoPageModule
      ),
  },
  {
    path: "profile-password",
    loadChildren: () =>
      import("./pages/profile/profile-password/profile-password.module").then(
        (m) => m.ProfilePasswordPageModule
      ),
  },
  {
    path: "forgot",
    loadChildren: () =>
      import("./pages/auth/forgot/forgot.module").then(
        (m) => m.ForgotPageModule
      ),
  },
  {
    path: "forgot-verify",
    loadChildren: () =>
      import("./pages/auth/forgot-verify/forgot-verify.module").then(
        (m) => m.ForgotVerifyPageModule
      ),
  },
  {
    path: "delete",
    loadChildren: () =>
      import("./pages/auth/delete/delete.module").then(
        (m) => m.DeletePageModule
      ),
  },
  {
    path: "delete-verify",
    loadChildren: () =>
      import("./pages/auth/delete-verify/delete-verify.module").then(
        (m) => m.DeleteVerifyPageModule
      ),
  },
  {
    path: "main",
    loadChildren: () =>
      import("./tabs/main/main.module").then((m) => m.MainPageModule),
  },
  {
    path: "task-list",
    loadChildren: () =>
      import("./pages/task/containers/task-list/task-list.module").then(
        (m) => m.TaskListPageModule
      ),
  },
  {
    path: "task-daily",
    loadChildren: () =>
      import("./pages/task/containers/task-list/task-list.module").then(
        (m) => m.TaskListPageModule
      ),
  },
  {
    path: "guide",
    loadChildren: () =>
      import("./pages/guide/guide.module").then((m) => m.GuidePageModule),
  },
  {
    path: "pomodoro",
    loadChildren: () =>
      import("./pages/pomodoro/pomodoro.module").then(
        (m) => m.PomodoroPageModule
      ),
  },
  {
    path: "task-form",
    loadChildren: () =>
      import("./pages/task/containers/task-form/task-form.module").then(
        (m) => m.TaskFormPageModule
      ),
  },
  {
    path: "task-detail",
    loadChildren: () =>
      import("./pages/task/containers/task-detail/task-detail.module").then(
        (m) => m.TaskDetailPageModule
      ),
  },
  {
    path: "task-report",
    loadChildren: () =>
      import("./pages/task/containers/task-report/task-report.module").then(
        (m) => m.TaskReportPageModule
      ),
  },
  {
    path: "routine-list",
    loadChildren: () =>
      import("./pages/routine/containers/routine-list/routine-list.module").then(
        (m) => m.RoutineListPageModule
      ),
  },
  {
    path: "routine-reports",
    loadChildren: () =>
      import("./pages/routine/containers/routine-reports/routine-reports.module").then(
        (m) => m.RoutineReportsPageModule
      ),
  },
  {
    path: "routine-form",
    loadChildren: () =>
      import("./pages/routine/containers/routine-form/routine-form.module").then(
        (m) => m.RoutineFormPageModule
      ),
  },
  {
    path: "goal-list",
    loadChildren: () =>
      import("./pages/goal-list/goal-list.module").then(
        (m) => m.GoalListPageModule
      ),
  },
  {
    path: "goal-form",
    loadChildren: () =>
      import("./pages/goal-form/goal-form.module").then(
        (m) => m.GoalFormPageModule
      ),
  },
  {
    path: "goal-detail",
    loadChildren: () =>
      import("./pages/goal-detail/goal-detail.module").then(
        (m) => m.GoalDetailPageModule
      ),
  },
  {
    path: "notification-list",
    loadChildren: () =>
      import("./pages/notification-list/notification-list.module").then(
        (m) => m.NotificationListPageModule
      ),
  },
  {
    path: "notification-detail",
    loadChildren: () =>
      import("./pages/notification-detail/notification-detail.module").then(
        (m) => m.NotificationDetailPageModule
      ),
  },
  {
    path: "project-detail/:project",
    loadChildren: () =>
      import("./pages/project-detail/project-detail.module").then(
        (m) => m.ProjectDetailPageModule
      ),
  },
  {
    path: "project-documents/:project",
    loadChildren: () =>
      import("./pages/project-documents/project-documents.module").then(
        (m) => m.ProjectDocumentsPageModule
      ),
  },
  {
    path: "project-tasks/:project",
    loadChildren: () =>
      import("./pages/project-tasks/project-tasks.module").then(
        (m) => m.ProjectTasksPageModule
      ),
  },
  {
    path: "project-form",
    loadChildren: () =>
      import("./pages/project-form/project-form.module").then(
        (m) => m.ProjectFormPageModule
      ),
  },

  {
    path: "month-list",
    loadChildren: () =>
      import("./pages/finance/containers/month-list/month-list.module").then(
        (m) => m.MonthListPageModule
      ),
  },
  {
    path: "month-form",
    loadChildren: () =>
      import("./pages/finance/containers/month-form/month-form.module").then(
        (m) => m.MonthFormPageModule
      ),
  },
  {
    path: "transaction-form",
    loadChildren: () =>
      import(
        "./pages/finance/containers/transaction-form/transaction-form.module"
      ).then((m) => m.TransactionFormPageModule),
  },
  {
    path: 'blog-list',
    loadChildren: () => import('./pages/blog/containers/blog-list/blog-list.module').then( m => m.BlogListPageModule)
  },
  {
    path: 'blog-detail',
    loadChildren: () => import('./pages/blog/containers/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule)
  },
  {
    path: "user-list",
    loadChildren: () =>
      import("./pages/user/containers/user-list/user-list.module").then(
        (m) => m.UserListPageModule
      ),
  },
  {
    path: "user-form",
    loadChildren: () =>
      import("./pages/user/containers/user-form/user-form.module").then(
        (m) => m.UserFormPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
