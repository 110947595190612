import { Component, Input, OnInit } from "@angular/core";
import { sumBy } from "lodash";
import uuidv4 from "src/app/utils/uuid";

declare let $: any;

@Component({
  selector: "app-transaction-boxes",
  templateUrl: "./transaction-boxes.component.html",
  styleUrls: ["./transaction-boxes.component.scss"],
})
export class TransactionBoxesComponent implements OnInit {
  @Input() month: any = {};
  @Input() items: any = [];
  @Input() prevItems: any = [];

  divId = uuidv4();

  constructor() {}

  ngOnInit() {
    setTimeout(() => this.initCarousel(), 1200);
  }

  sumPrevDataWithType(type = "") {
    return sumBy(this.prevItems, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumDataWithType(type = "") {
    return sumBy(this.items, (o) => {
      if (o.type === type) {
        return o.amount;
      }

      return 0;
    });
  }

  sumNetOverall() {
    return this.sumDataWithType("income") - this.sumDataWithType("expense");
  }

  initCarousel() {
    $("#" + this.divId).owlCarousel({
      autoWidth: true,
      loop: true,
      margin: 16,
      nav: false,
      dots: false,
    });
  }
}
