import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import LABELS from "src/app/constants/LABELS";
import { FriendFormPage } from "src/app/modals/friend-form/friend-form.page";
import { ProjectFormPage } from "src/app/modals/project-form/project-form.page";
import { AuthService } from "src/app/services/auth/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { FriendService } from "src/app/services/friend/friend.service";
import { TaskService } from "src/app/services/task/task.service";
import { UserService } from "src/app/services/user/user.service";
import {
  friendCreate,
  friendDelete,
  friendList,
} from "src/app/store/friend/friend.action";
import { selectorFriend } from "src/app/store/friend/friend.selector";
import {
  projectCreate
} from "src/app/store/project/project.action";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  providers: [NavController, ModalController],
})
export class MenuComponent implements OnInit {
  @Input() active = "home";
  @Output() onNew = new EventEmitter();

  logged: any = {};
  totalNewNotifications: any = 0;
  totalTasks: any = 0;

  projects: any = [];
  friends: any = [];

  constructor(
    private auth: AuthService,
    private taskService: TaskService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private commonService: CommonService,
    private store: Store,
    private userService: UserService,
    private friendService: FriendService,
    private alertController: AlertController,
    private translate: TranslateService
  ) {
    this.store.select(selectorFriend).subscribe((state) => {
      this.friends = state.items;
    });
  }

  ngOnInit() {
    this.logged = this.auth.getLoggedUser() || {};

    // if (!this.logged.labels || !this.logged.labels.length) {
    this.logged.labels = LABELS;

    this.auth.setLoggedUser(this.logged);

    this.userService
      .save({
        id: this.logged.id,
        labels: this.logged.labels,
      })
      .subscribe();
    // }

    this.getFriends();
    this.getProfiles();

    setInterval(() => {
      this.logged = this.auth.getLoggedUser() || {};
    }, 5000);

    setInterval(() => {
      this.getProfiles();
    }, 30000);
  }

  getFriends() {
    this.friendService
      .list({
        user: this.logged.id,
        $page: 1,
        $limit: 99,
        // $sort: { title: 1 },
        $populate: ['friend', 'user']
      })
      .subscribe((resp) =>
        this.store.dispatch(friendList({ items: resp.data }))
      );
  }

  getProfiles(id = null) {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }

    this.taskService
      .list({
        $page: 1,
        $limit: Number.MAX_SAFE_INTEGER,
        status: "new",
      })
      .subscribe((resp) => {
        this.totalTasks = resp.total;
      });
  }

  changePage($page) {
    this.navCtrl.navigateRoot($page, { replaceUrl: true });
    this.menuCtrl.close();
  }

  async openProjectFormModal() {
    const modal: any = await this.modalCtrl.create({
      component: ProjectFormPage,
    });

    modal.onDidDismiss().then(($event: any) => {
      if ($event.data) {
        this.store.dispatch(projectCreate({ item: $event.data }));
        // this.onNew.emit($event.data);
      }
    });
    return await modal.present();
  }

  async openFriendFormModal() {
    const modal: any = await this.modalCtrl.create({
      component: FriendFormPage,
    });

    modal.onDidDismiss().then(($event: any) => {
      if ($event.data) {
        this.store.dispatch(friendCreate({ item: $event.data }));
        // this.onNew.emit($event.data);
      }
    });
    return await modal.present();
  }

  goTo(url) {
    this.navCtrl.navigateRoot(url, { replaceUrl: true });
    this.menuCtrl.close();
  }

  logout() {
    this.commonService.confirm(() => {
      this.auth.signOut();
      this.menuCtrl.close();
      this.navCtrl.navigateRoot("/login", { replaceUrl: true });
    });
  }

  async deleteFriend(friend) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant('Delete Your Friend'),
      message: this.translate.instant('Are you sure to want to delete this friend from your list?'),
      buttons: [
        {
          text: this.translate.instant("Cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translate.instant("Confirm"),
          handler: () => {
            this.friendService.delete(friend).subscribe((resp) => {
              this.store.dispatch(friendDelete({ item: resp }));
            });
          },
        },
      ],
    });

    await alert.present();
  }

  currentLanguage = window.localStorage.getItem('language') || 'en';
  changeLanguage(lang = 'en') {
    this.translate.use(lang);
    this.currentLanguage = lang;
    window.localStorage.setItem('language', lang);

    console.log(this.currentLanguage);
  }
}
