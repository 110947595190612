import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-finance-welcome-message',
  templateUrl: './finance-welcome-message.component.html',
  styleUrls: ['./finance-welcome-message.component.scss'],
})
export class FinanceWelcomeMessageComponent implements OnInit {
  profile: any = {};

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.profile = this.auth.getLoggedUser();
  }

}
