import { createReducer, on } from "@ngrx/store";
import { taskAll, taskCreate, taskDelete, taskList, taskUpdate } from "./task.action";

export const INIT_STATE = {
  items: [],
};

export const reducerTask = createReducer(
  INIT_STATE,
  on(taskAll, (state, { items }) => {
    return {
      ...state,
      allItems: [...items],
    };
  }),
  on(taskList, (state, { items }) => {
    return {
      ...state,
      items: [...items],
    };
  }),
  on(taskCreate, (state, { item }) => {
    return {
      ...state,
      items: [...state.items, item],
    };
  }),
  on(taskUpdate, (state, { item }) => {
    let index = state.items.findIndex((o) => o.id === item.id);

    const items = [...state.items];

    if (index > -1) {
      items[index] = item;
    }

    return {
      ...state,
      items: items,
    };
  }),
  on(taskDelete, (state, { item }) => {
    let items = state.items.filter((o) => o.id !== item.id);

    return {
      ...state,
      items: items,
    };
  })
);
