const TRANSACTION_CATEGORIES = [
  {
    value: "SALARY",
    title: "Salary",
    icon: "mdi mdi-credit-card",
    color: "success",
    subs: [],
  },
  {
    value: "HOUSING",
    title: "Housing",
    icon: "mdi mdi-home-city",
    color: "primary",
    subs: [
      {
        value: "HOUSING_Mortgage_or_rent",
        title: "Mortgage or rent",
      },
      {
        value: "HOUSING_Second_Mortgage_or_rent",
        title: "Second mortgage or rent",
      },
      {
        value: "HOUSING_Phone",
        title: "Phone",
      },
      {
        value: "HOUSING_Electricity",
        title: "Electricity",
      },
      {
        value: "HOUSING_Gas",
        title: "Gas",
      },
      {
        value: "HOUSING_Water_and_sewer",
        title: "Water and sewer",
      },
      {
        value: "HOUSING_Cable",
        title: "Cable",
      },
      {
        value: "HOUSING_Waste removal",
        title: "Waste removal",
      },
      {
        value: "HOUSING_Maintenance_or_repairs",
        title: "Maintenance or repairs",
      },
      {
        value: "HOUSING_Supplies",
        title: "Supplies",
      },
      {
        value: "HOUSING_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "TRANSPORTATION",
    title: "Transportation",
    icon: "mdi mdi-train-car",
    color: "warning",
    subs: [
      {
        value: "TRANSPORTATION_Vehicle_1_payment",
        title: "Vehicle 1 payment",
      },
      {
        value: "TRANSPORTATION_Vehicle_2_payment",
        title: "Vehicle 2 payment",
      },
      {
        value: "TRANSPORTATION_Bus_taxi_fare",
        title: "Bus/taxi fare",
      },
      {
        value: "TRANSPORTATION_Insurance",
        title: "Insurance",
      },
      {
        value: "TRANSPORTATION_Licensing",
        title: "Licensing",
      },
      {
        value: "TRANSPORTATION_Fuel",
        title: "Fuel",
      },
      {
        value: "TRANSPORTATION_Maintenance",
        title: "Maintenance",
      },
      {
        value: "TRANSPORTATION_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "INSURANCE",
    title: "Insurance",
    icon: "mdi mdi-hospital-box-outline",
    color: "secondary",
    subs: [
      {
        value: "INSURANCE_Home",
        title: "Home",
      },
      {
        value: "INSURANCE_Health",
        title: "Health",
      },
      {
        value: "INSURANCE_Life",
        title: "Life",
      },
      {
        value: "INSURANCE_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "FOOD",
    title: "Food",
    icon: "mdi mdi-food-fork-drink",
    color: "danger",
    subs: [
      {
        value: "FOOD_Groceries",
        title: "Groceries",
      },
      {
        value: "FOOD_Dining_out",
        title: "Dining out",
      },
      {
        value: "FOOD_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "CHILDREN",
    title: "Children",
    icon: "mdi mdi-teddy-bear",
    color: "medium",
    subs: [
      {
        value: "CHILDREN_Medical",
        title: "Medical",
      },
      {
        value: "CHILDREN_Clothing",
        title: "Clothing",
      },
      {
        value: "CHILDREN_School_tuition",
        title: "School tuition",
      },
      {
        value: "CHILDREN_School_supplies",
        title: "School supplies",
      },
      {
        value: "CHILDREN_Organization_dues_fees",
        title: "Organization dues/fees",
      },
      {
        value: "CHILDREN_Lunch_money",
        title: "Lunch money",
      },
      {
        value: "CHILDREN_Child_care",
        title: "Child care",
      },
      {
        value: "CHILDREN_Toys_games",
        title: "Toys/games",
      },
      {
        value: "CHILDREN_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "SAVINGS_INVESTMENTS",
    title: "Savings/investments",
    icon: "mdi mdi-cube-outline",
    color: "primary",
    subs: [
      {
        value: "SAVINGS_INVESTMENTS_Retirement_account",
        title: "Retirement account",
      },
      {
        value: "SAVINGS_INVESTMENTS_Investment_account",
        title: "Investment account",
      },
      {
        value: "SAVINGS_INVESTMENTS_Gold",
        title: "Gold",
      },
      {
        value: "SAVINGS_INVESTMENTS_Cash",
        title: "Cash",
      },
      {
        value: "SAVINGS_INVESTMENTS_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "LOANS",
    title: "Loans",
    icon: "mdi mdi-credit-card-clock",
    color: "secondary",
    subs: [
      {
        value: "LOANS_Personal",
        title: "Personal",
      },
      {
        value: "LOANS_Student",
        title: "Student",
      },
      {
        value: "LOANS_Credit_card_1",
        title: "Credit card 1",
      },
      {
        value: "LOANS_Credit_card_2",
        title: "Credit card 2",
      },
      {
        value: "LOANS_Credit_card_3",
        title: "Credit card 3",
      },
      {
        value: "LOANS_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "ENTERTAIMENT",
    title: "Entertainment",
    icon: "mdi mdi-music",
    color: "success",
    subs: [
      {
        value: "ENTERTAIMENT_Streaming_apps",
        title: "Streaming apps",
      },
      {
        value: "ENTERTAIMENT_Online_games",
        title: "Online games",
      },
      {
        value: "ENTERTAIMENT_Movies",
        title: "Movies",
      },
      {
        value: "ENTERTAIMENT_Concerts",
        title: "Concerts",
      },
      {
        value: "ENTERTAIMENT_Sporting_events",
        title: "Sporting events",
      },
      {
        value: "ENTERTAIMENT_Live theater",
        title: "Live_theater",
      },
      {
        value: "ENTERTAIMENT_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "PERSONAL_CARE",
    title: "Personal care",
    icon: "mdi mdi-paper-cut-vertical",
    color: "warning",
    subs: [
      {
        value: "PERSONAL_CARE_Medical",
        title: "Medical",
      },
      {
        value: "PERSONAL_CARE_Hair_nails",
        title: "Hair/nails",
      },
      {
        value: "PERSONAL_CARE_Clothing",
        title: "Clothing",
      },
      {
        value: "PERSONAL_CARE_Dry_cleaning",
        title: "Dry cleaning",
      },
      {
        value: "PERSONAL_CARE_Health_club",
        title: "Health club",
      },
      {
        value: "PERSONAL_CARE_Organization_dues_fees",
        title: "Organization dues/fees",
      },
      {
        value: "PERSONAL_CARE_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "PETS",
    title: "Pets",
    icon: "mdi mdi-dog-service",
    color: "danger",
    subs: [
      {
        value: "PETS_Food",
        title: "Food",
      },
      {
        value: "PETS_Medical",
        title: "Medical",
      },
      {
        value: "PETS_Grooming",
        title: "Grooming",
      },
      {
        value: "PETS_Toys",
        title: "Toys",
      },
      {
        value: "PETS_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "TAXES",
    title: "Taxes",
    icon: "mdi mdi-receipt-text",
    color: "medium",
    subs: [
      {
        value: "TAXES_Federal",
        title: "Federal",
      },
      {
        value: "TAXES_State",
        title: "State",
      },
      {
        value: "TAXES_Local",
        title: "Local",
      },
      {
        value: "TAXES_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "LEGAL",
    title: "Legal",
    icon: "mdi mdi-scale-balance",
    color: "primary",
    subs: [
      {
        value: "LEGAL_Attorney",
        title: "Attorney",
      },
      {
        value: "LEGAL_Alimony",
        title: "Alimony",
      },
      {
        value: "LEGAL_Payments",
        title: "Payments",
      },
      {
        value: "LEGAL_Other",
        title: "Other",
      },
    ],
  },
  {
    value: "GIFTS_AND_DONATIONS",
    title: "Gifts and donations",
    icon: "mdi mdi-gift-open-outline",
    color: "danger",
    subs: [
      {
        value: "GIFTS_AND_DONATIONS_Charity_1",
        title: "Charity 1",
      },
      {
        value: "GIFTS_AND_DONATIONS_Charity_2",
        title: "Charity 2",
      },
      {
        value: "GIFTS_AND_DONATIONS_Charity_3",
        title: "Charity 3",
      },
    ],
  },
];

export default TRANSACTION_CATEGORIES;
