import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { MenuComponent } from "./menu/menu.component";
import { SummaryComponent } from "./summary/summary.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { RoutineItemComponent } from "./routine-item/routine-item.component";
import { GoalItemComponent } from "./goal-item/goal-item.component";
import { NotificationItemComponent } from "./notification-item/notification-item.component";
import { EmptyListComponent } from "./empty-list/empty-list.component";
import { LoadingListComponent } from "./loading-list/loading-list.component";
import { ProjectSegmentsComponent } from "./project-segments/project-segments.component";
import { FinanceSharedModule } from "../pages/finance/shared/shared.module";
import { CommentsComponent } from "./comments/comments.component";
import { TranslateModule } from "@ngx-translate/core";
import { AttachmentsComponent } from "./attachments/attachments.component";
import { LabelPriorityComponent } from "./label-priority/label-priority.component";

@NgModule({
  declarations: [
    MenuComponent,
    SummaryComponent,
    RoutineItemComponent,
    GoalItemComponent,
    NotificationItemComponent,
    EmptyListComponent,
    LoadingListComponent,
    ProjectSegmentsComponent,
    CommentsComponent,
    AttachmentsComponent,
    LabelPriorityComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    FinanceSharedModule,
    TranslateModule,
    NgCircleProgressModule
  ],
  exports: [
    MenuComponent,
    SummaryComponent,
    RoutineItemComponent,
    GoalItemComponent,
    NotificationItemComponent,
    EmptyListComponent,
    LoadingListComponent,
    ProjectSegmentsComponent,
    CommentsComponent,
    AttachmentsComponent,
    LabelPriorityComponent
  ],
  entryComponents: [
    MenuComponent,
    SummaryComponent,
    RoutineItemComponent,
    GoalItemComponent,
    NotificationItemComponent,
    EmptyListComponent,
    LoadingListComponent,
    ProjectSegmentsComponent,
    AttachmentsComponent,
    LabelPriorityComponent
  ],
})
export class ComponentsModule {}
