import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ToastController,
} from "@ionic/angular";

import _ from "lodash";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";

import * as moment from "moment";
import PERSONAL_HABITS from "src/app/pages/routine/constants/PERSONAL_HABITS";

declare let $: any;

// import { Capacitor } from "@capacitor/core";

// import { Insomnia } from "@awesome-cordova-plugins/insomnia";
// import { ScreenBrightness } from "@capacitor-community/screen-brightness";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  loading: any = null;

  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  async confirm(
    cb,
    message = "Are you sure to want to do this?",
    header = "Confirm"
  ) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            if (cb) {
              cb();
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async showMessage(msg, heading = "Alert", position: any = "top") {
    const alert = await this.toastCtrl.create({
      header: heading,
      message: msg,
      position: position,
      translucent: true,
      buttons: ["OK"],
    });

    alert.present();
  }

  async showToast(
    msg = "Cannot connect to server to get data. Please check and try again!"
  ) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
    });
    toast.present();
  }

  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: "Loading ...",
      duration: 100,
    });
    await this.loading.present();
  }

  async closeLoading(isRun = true) {
    if (!this.loading || !isRun) {
      return;
    }

    setTimeout(async () => await this.loading.dismiss(), 100);
  }

  transform(value: string): any {
    return value.replace(/<.*?>/g, ""); // replace tags
  }

  keepAwake() {
    // if (Capacitor.isNativePlatform()) {
    //   Insomnia.keepAwake().then(
    //     () => console.log("keepAwake: success"),
    //     () => console.log("keepAwake: error")
    //   );
    // } else {
    //   console.warn("keepAwake: NOT SUPPORT WEB");
    // }
  }

  allowSleepAgain() {
    // if (Capacitor.isNativePlatform()) {
    //   Insomnia.allowSleepAgain().then(
    //     () => console.log("keepAwake: success"),
    //     () => console.log("keepAwake: error")
    //   );
    // } else {
    //   console.warn("allowSleepAgain: NOT SUPPORT WEB");
    // }
  }

  async setBrightness(brightness = 0.5) {
    // if (Capacitor.isNativePlatform()) {
    //   await ScreenBrightness.setBrightness({ brightness });
    // } else {
    //   console.warn("setBrightness: NOT SUPPORT WEB");
    // }
  }

  async getBrightness() {
    // if (Capacitor.isNativePlatform()) {
    //   const result = await ScreenBrightness.getBrightness();
    //   return result.brightness;
    // } else {
    //   console.warn("getBrightness: NOT SUPPORT WEB");
    //   return 1;
    // }
  }

  initData() {
    const userId = this.auth.getLoggedUser("id");
    if (!userId) {
      return;
    }

    const params: any = {
      startDate: moment().startOf("date").format(),
      endDate: moment().endOf("date").format(),
      currentDate: moment().format(),
    };

    const query = $.param(params);
    const url = `${environment.apiUrl}/init-data/${userId}?${query}`;

    return this.http.get(url);
  }

  summaryData() {
    const url = `${environment.apiUrl}/summary`;
    return this.http.get(url);
  }

  initWelcomeMessage() {
    const userId = this.auth.getLoggedUser("id");
    if (!userId) {
      return;
    }

    const url = `${environment.apiUrl}/init-notification-welcome/${userId}`;

    return this.http.get(url);
  }

  list(inParams: any = {}): any {
    const params = { ...inParams };

    if (params.$page) {
      params.$skip = (params.$page - 1) * params.$limit;
    }
    delete params.$page;

    if (!params.status) {
      delete params.status;
    }

    const query = $.param(params);
    const url = `${environment.apiUrl}/tasks?${query}`;
    return this.http.get(url);
  }

  setTrackingItem(item, action, type) {
    window.localStorage.setItem(
      "TRACKING_ITEM",
      JSON.stringify({
        item,
        type,
        action,
      })
    );
  }
  trackingItems(items, type = "task") {
    let TRACKING_ITEM: any = window.localStorage.getItem("TRACKING_ITEM");
    if (!TRACKING_ITEM) {
      return items;
    }

    TRACKING_ITEM = JSON.parse(TRACKING_ITEM);

    if (type !== TRACKING_ITEM.type) {
      return items;
    }

    if (TRACKING_ITEM.action === "DELETE") {
      _.remove(items, (o: any) => o.id === TRACKING_ITEM.item.id);
    } else if (TRACKING_ITEM.action === "ADD") {
      items.unshift(TRACKING_ITEM.item);
    } else {
      const index = _.findIndex(
        items,
        (o: any) => o.id === TRACKING_ITEM.item.id
      );
      if (index > -1) {
        items[index] = TRACKING_ITEM.item;
      }
    }

    window.localStorage.removeItem("TRACKING_ITEM");
    return items;
  }

  cleanTrackingItems(type = "task") {
    let TRACKING_ITEM: any = window.localStorage.getItem("TRACKING_ITEM");
    if (!TRACKING_ITEM) {
      return;
    }

    TRACKING_ITEM = JSON.parse(TRACKING_ITEM);

    if (type !== TRACKING_ITEM.type) {
      return;
    }
    window.localStorage.removeItem("TRACKING_ITEM");
  }

  getRoutineThumbnail(item) {
    const type = _.find(PERSONAL_HABITS, (o) => item.type == o.id);

    if (type) {
      return type.thumbnail;
    }

    return "assets/images/routine-other.jpeg";
  }

  /**
   * Upload hình ảnh lên server
   * @param img
   * @param callback
   */
  uploadImage(img: any, callback) {
    let formData: FormData = new FormData();
    formData.append("image", img, img.name);

    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (ev: ProgressEvent) => {
      //You can handle progress events here if you want to track upload progress (I used an observable<number> to fire back updates to whomever called this upload)
    });

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let data = JSON.parse(xhr.responseText);
        callback(data);
      }
    };

    xhr.open("POST", `${environment.apiUrl}/upload/image`, true);
    xhr.send(formData);
  }

  nl2br(str) {
    // Check if the input is valid
    if (typeof str !== "string") {
      return "";
    }

    // Replace all newline characters with <br>
    return str.replace(/\n/g, "<br>");
  }
}
