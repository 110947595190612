import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import {filter} from "lodash";

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class MonthService {
  constructor(public http: HttpClient) {}

  list(inParams: any = {}): any {
    const params = {...inParams};

    if (params.$page) {
      params.$skip = (params.$page - 1) * (params.$limit);
    }
    delete params.$page;

    if (!params.status) {
      delete params.status;
    }

    const query = $.param(params);
    const url = `${environment.apiUrl}/finance/months?${query}`;
    return this.http.get(url);
  }

  save(model): any {
    if (model.id) {
      const updateUrl = `${environment.apiUrl}/finance/months/${model.id}`;
      return this.http.patch(updateUrl, model);
    }

    delete model.id;
    const url = `${environment.apiUrl}/finance/months`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/finance/months/${id}`;
    const params: any = {
      $populate: ["user", "author"],
    };

    return this.http.get(url, { params });
  }

  delete(model): any {
    const url = `${environment.apiUrl}/finance/months/${model.id}`;
    return this.http.delete(url);
  }

  percentage(item) {
    if (!item) {
      return 0;
    }
    
    if (item.status === 'completed') {
      return 100;
    }

    const {goals} = item;

    if (goals && goals.length) {
      const completedGoals = filter(goals, (o: any) => o.status === 'completed');

      return (completedGoals.length / goals.length * 100).toFixed(0);
    }

    return 0;
  }
}
