import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TransactionAmountComponent } from './transaction-amount/transaction-amount.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionSummaryChartComponent } from './transaction-summary-chart/transaction-summary-chart.component';
import { TransactionSummaryComponent } from './transaction-summary/transaction-summary.component';
import { FinanceWelcomeMessageComponent } from './finance-welcome-message/finance-welcome-message.component';
import { TransactionBoxesComponent } from './transaction-boxes/transaction-boxes.component';



@NgModule({
  declarations: [
    TransactionListComponent,
    TransactionAmountComponent,
    TransactionSummaryComponent,
    TransactionSummaryChartComponent,
    FinanceWelcomeMessageComponent,
    TransactionBoxesComponent
  ],
  exports: [
    TransactionListComponent,
    TransactionAmountComponent,
    TransactionSummaryComponent,
    TransactionSummaryChartComponent,
    FinanceWelcomeMessageComponent,
    TransactionBoxesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule
  ]
})
export class FinanceSharedModule { }
