import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  AlertController,
  IonicModule,
  IonicRouteStrategy,
  LoadingController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { NgCircleProgressModule } from "ng-circle-progress";

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderInterceptor } from "./HttpInterceptor";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { reducerTransaction } from "./pages/finance/stores/transaction.reducer";
import { reducerRoutine } from "./pages/routine/store/routine.reducer";
import { CommonService } from "./services/common/common.service";
import { UserService } from "./services/user/user.service";
import { reducerDocument } from "./store/document/document.reducer";
import { reducerFriend } from "./store/friend/friend.reducer";
import { reducerProject } from "./store/project/project.reducer";
import { reducerTask } from "./store/task/task.reducer";
import { reducerUser } from "./store/user/user.reducer";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      defaultLanguage: window.localStorage.getItem("language") || "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    IonicModule.forRoot({
      mode: "ios",
    }),

    StoreModule.forRoot({
      project: reducerProject,
      task: reducerTask,
      document: reducerDocument,
      transaction: reducerTransaction,
      routine: reducerRoutine,
      friend: reducerFriend,
      user: reducerUser,
    }),

    AppRoutingModule,
    ComponentsModule,

    NgCircleProgressModule.forRoot({
      backgroundColor: "#FDB900",
      radius: 60,
      // toFixed: "1",
      maxPercent: 200,
      units: "%",
      unitsColor: "#483500",
      outerStrokeWidth: 5,
      outerStrokeColor: "#FFFFFF",
      innerStrokeColor: "#FFFFFF",
      titleColor: "#483500",
      subtitleColor: "#483500",
      showSubtitle: false,
      showInnerStroke: false,
      startFromZero: false,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AlertController,
    LoadingController,
    NavController,
    ToastController,

    CommonService,
    UserService,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
