import { Component, Input, OnInit } from '@angular/core';
import { CommentService } from 'src/app/services/comment/comment.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  @Input() objectType = null;
  @Input() objectId = null;

  comment: any = '';
  comments: any = [];

  constructor(private commentService: CommentService) {}

  ngOnInit() {
    this.commentService
      .list({
        objectType: this.objectType,
        objectId: this.objectId,
        $sort: { createdAt: -1 },
        $populate: ['author']
      })
      .subscribe((resp) => {
        this.comments = resp.data;
      });
  }

  send() {
    this.commentService
      .save({
        objectType: this.objectType,
        objectId: this.objectId,
        title: this.comment,
        $populate: ['author']
      })
      .subscribe((resp) => {
        this.comments.unshift(resp);
        this.comment = '';
      });
  }
}
