import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

import * as moment from "moment";
import _ from "lodash";
import { Store } from "@ngrx/store";
import { routineUpdate } from "src/app/pages/routine/store/routine.action";

declare let $: any;

@Injectable({
  providedIn: "root",
})
export class RoutineService {
  constructor(public http: HttpClient, private store: Store) {}

  list(inParams: any = {}): any {
    const params = { ...inParams };

    if (params.$page) {
      params.$skip = (params.$page - 1) * params.$limit;
    }
    delete params.$page;

    if (!params.status) {
      delete params.status;
    }

    if (params.$search) {
      params.title = {$like: `%${params.$search}%`};
      delete params.$search;
    }

    const query = $.param(params);
    const url = `${environment.apiUrl}/routines?${query}`;
    return this.http.get(url);
  }

  save(model, params = { $populate: ["friends", "author"] }): any {
    const query = $.param(params);

    if (model.id) {
      const updateUrl = `${environment.apiUrl}/routines/${model.id}?${query}`;
      return this.http.patch(updateUrl, model);
    }

    delete model.id;
    const url = `${environment.apiUrl}/routines?${query}`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/routines/${id}`;
    const params: any = {
      $populate: ["user", "author", "friends"],
    };

    return this.http.get(url, { params });
  }

  delete(model): any {
    const url = `${environment.apiUrl}/routines/${model.id}`;
    return this.http.delete(url);
  }

  percentage(item) {
    if (!item) {
      return 0;
    }

    if (item.status === "completed") {
      return 100;
    }

    const { goals } = item;

    if (goals && goals.length) {
      const completedGoals = _.filter(
        goals,
        (o: any) => o.status === "completed"
      );

      return ((completedGoals.length / goals.length) * 100).toFixed(0);
    }

    return 0;
  }

  saveCompleteDays(routineId, task) {
    this.detail(routineId).subscribe((resp) => {
      const completeDays = resp.completeDays || {};

      completeDays[moment(task.createdAt).format("YYYY-MM-DD")] = task.status;

      this.save({
        id: routineId,
        completeDays,
      }).subscribe((resp) => {
        this.store.dispatch(routineUpdate({ item: resp }));
      });
    });
  }
}
