import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

import _ from "lodash";

declare let $: any;

@Injectable({
  providedIn: "root",
})
export class FriendService {
  constructor(public http: HttpClient) {}

  list(inParams: any = {$populate: ['user', 'friend']}): any {
    const params = {...inParams};

    if (params.$page) {
      params.$skip = (params.$page - 1) * (params.$limit);
    }
    delete params.$page;

    if (!params.status) {
      delete params.status;
    }

    const query = $.param(params);
    const url = `${environment.apiUrl}/friends?${query}`;
    return this.http.get(url);
  }

  save(model, params = {$populate: ['user', 'friend']}): any {
    const query = $.param(params);

    if (model.id) {
      const updateUrl = `${environment.apiUrl}/friends/${model.id}?${query}`;
      return this.http.patch(updateUrl, model);
    }

    delete model.id;
    const url = `${environment.apiUrl}/friends?${query}`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/friends/${id}`;
    const params: any = {
      $populate: ["user", "author"],
    };

    return this.http.get(url, { params });
  }

  delete(model): any {
    const url = `${environment.apiUrl}/friends/${model.id}`;
    return this.http.delete(url);
  }
}
