import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

// @ts-ignore
import moment from 'moment';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public http: HttpClient) {
  }

  list(inParams: any = {}): any {
    const params = {...inParams};

    if (!params.$search) {
      delete params.$search;
    }

    if (params['createdAt[$gte]']) {
      params['createdAt[$gte]'] = moment(params['createdAt[$gte]']).startOf('day').utc().format();
    } else {
      delete params['createdAt[$gte]'];
    }


    if (params['createdAt[$lte]']) {
      params['createdAt[$lte]'] = moment(params['createdAt[$lte]']).endOf('day').utc().format();
    } else {
      delete params['createdAt[$lte]'];
    }

    if (params.$page) {
      params.$skip = (params.$page - 1) * (params.$limit);
      delete params.$page;
    }

    const url = `${environment.apiUrl}/notifications?${$.param(params)}`;
    return this.http.get(url);
  }

  save(model, params = {}): any {
    if (model.id) {
      const updateUrl = `${environment.apiUrl}/notifications/${model.id}?${$.param(params)}`;
      return this.http.patch(updateUrl, model);
    }

    delete model.id;

    const url = `${environment.apiUrl}/notifications?${$.param(params)}`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/notifications/${id}`;
    return this.http.get(url);
  }

  delete(model): any {
    const url = `${environment.apiUrl}/notifications/${model.id}`;
    return this.http.delete(url);
  }
}
