;import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import {findIndex, sumBy} from "lodash";

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent implements OnInit, OnChanges {

  @Input() loaded = false;
  @Input() items: any = [];

  groupedItems: any = {};

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.groupedItems = this.groupTransactionsByDay(this.items);
    }
  }

  groupTransactionsByDay(transactions) {
    const groupedTransactions = [];
  
    transactions.forEach((transaction) => {
      // Lấy ngày của giao dịch (không tính giờ phút)
      const transactionDate = moment(transaction.transactionDate).format('MMM DD YYYY');
  
      // Tạo một nhóm nếu chưa tồn tại
      const index = findIndex(groupedTransactions, o => o.date === transactionDate);

      if (index === -1) {
        const newData = {
          date: transactionDate,
          transactions: []
        }

        newData.transactions.push(transaction);
        groupedTransactions.push(newData);
        return;
      }
  
      // Thêm giao dịch vào nhóm tương ứng
      groupedTransactions[index].transactions.push(transaction)
    });
  
    return groupedTransactions;
  }

  sumAmountInGroup(groupedItem) {
    const sumIncome = sumBy(groupedItem.transactions, transaction => {
      return transaction.type === 'income' ?  transaction.amount : 0;
    });

    const sumExpense = sumBy(groupedItem.transactions, transaction => {
      return transaction.type === 'expense' ?  transaction.amount : 0;
    });

    return sumIncome - sumExpense;
  }

}
