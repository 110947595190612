import { createReducer, on } from "@ngrx/store";
import { documentAll, documentCreate, documentDelete, documentList, documentUpdate } from "./document.action";

export const INIT_STATE = {
  items: [],
};

export const reducerDocument = createReducer(
  INIT_STATE,
  on(documentAll, (state, { items }) => {
    return {
      ...state,
      allItems: [...items],
    };
  }),
  on(documentList, (state, { items }) => {
    return {
      ...state,
      items: [...items],
    };
  }),
  on(documentCreate, (state, { item }) => {
    return {
      ...state,
      items: [...state.items, item],
    };
  }),
  on(documentUpdate, (state, { item }) => {
    let index = state.items.findIndex((o) => o.id === item.id);

    const items = [...state.items];

    if (index > -1) {
      items[index] = item;
    }

    return {
      ...state,
      items: items,
    };
  }),
  on(documentDelete, (state, { item }) => {
    let items = state.items.filter((o) => o.id !== item.id);

    return {
      ...state,
      items: items,
    };
  })
);
