const TRANSACTION_TYPES = [
  {
    value: "expense",
    title: "Expense",
    color: '#FFEB3B'
  },
  {
    value: "income",
    title: "Income",
    color: '#4CAF50'
  },
];

export default TRANSACTION_TYPES;
