import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-priority',
  templateUrl: './label-priority.component.html',
  styleUrls: ['./label-priority.component.scss'],
})
export class LabelPriorityComponent implements OnInit {
  @Input() priority: any = null; 
  constructor() {}

  ngOnInit() {}

}
