import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit {
  @Input() attachments: any = [];
  @Output() attachmentsChange = new EventEmitter<Array<any>>();

  ngOnInit() {}

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileData = {
          name: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result as string,
          creationDate: new Date().toISOString(),
        };

        this.attachments.push(fileData);
        this.attachmentsChange.emit(this.attachments); // Emit sự thay đổi
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  }

  downloadAttachment(attachment: any) {
    const link = document.createElement("a");
    link.href = attachment.url;
    link.download = attachment.name;
    link.click();
  }

  deleteAttachment(attachment: any) {
    this.attachments = this.attachments.filter((item) => item !== attachment);
    this.attachmentsChange.emit(this.attachments); // Emit sự thay đổi
  }
}
