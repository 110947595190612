import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavController } from "@ionic/angular";
import { RoutineService } from "../../services/routine/routine.service";

import _ from "lodash";

import * as moment from "moment";
import { TaskService } from "src/app/services/task/task.service";
import { CommonService } from "src/app/services/common/common.service";

@Component({
  selector: "app-routine-item",
  templateUrl: "./routine-item.component.html",
  styleUrls: ["./routine-item.component.scss"],
})
export class RoutineItemComponent implements OnInit {
  @Input() active = false;
  @Input() item = null;

  @Output() delete: any = new EventEmitter();
  @Output() update: any = new EventEmitter();

  constructor(
    private navCtrl: NavController,
    private routineService: RoutineService,
    private taskService: TaskService,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  async openDetail() {
    this.navCtrl.navigateForward(["/routine-form", { id: this.item.id }]);
    // const modal: any = await this.modalCtrl.create({
    //   component: RoutineDetailPage,
    //   componentProps: {
    //     item: this.item,
    //   },
    // });

    // modal.onDidDismiss().then(($event: any) => {
    //   if (!$event.data) {
    //     return;
    //   }

    //   const { type, item } = $event.data;
    //   switch (type) {
    //     case "DELETE":
    //       this.delete.emit(item);
    //       break;
    //     case "UPDATE":
    //       this.update.emit(item);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    // return await modal.present();
  }

  isOverDate() {
    if (this.item.status === "completed") {
      return false;
    }
    const $startDate = moment(this.item.start_date).endOf("day");
    const $today = moment().startOf("day");
    return $startDate.isBefore($today);
  }

  isActive() {
    return this.item.status === "completed";
  }

  onChange() {
    this.item.status = this.item.status === "active" ? "new" : "active";

    this.routineService
      .save({ id: this.item.id, status: this.item.status })
      .subscribe();
  }

  getPercentage() {
    return this.routineService.percentage(this.item);
  }

  create() {
    this.commonService.confirm(() => {
      this.taskService
        .save({
          title: this.item.title,
          description: this.item.description,
          pomodoro: this.item.pomodoro,
          type: this.item.type,
          thumbnail: this.item.thumbnail,
          goals: this.item.goals,
          start_date: Date.now(),
          user: this.item.user,
          author: this.item.user,
        })
        .subscribe(
          (resp) => {
            this.commonService.setTrackingItem(resp, "ADD", "task");
            this.commonService.showMessage("Created Successfully!");
          },
          (err) => {
            this.commonService.showMessage(err.error.message);
          }
        );
    });
  }
}
