import { Component, Input, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";

import * as moment from "moment";
import { TaskService } from "src/app/services/task/task.service";
import uuidv4 from "src/app/utils/uuid";
import { Store } from "@ngrx/store";
import { selectorTask } from "src/app/store/task/task.selector";
import { taskAll } from "src/app/store/task/task.action";
import { TranslateService } from "@ngx-translate/core";

declare let Highcharts: any;

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  @Input() tasks = [];
  @Input() loaded = [];

  allTasks = [];

  uuid1 = uuidv4();
  uuid2 = uuidv4();

  constructor(
    private taskService: TaskService,
    private commonService: CommonService,
    private store: Store,
    private translate: TranslateService
  ) {
    this.store
      .select(selectorTask)
      .subscribe((state) => (this.allTasks = state.allItems));
  }

  ngOnInit() {
    this.store.dispatch(taskAll({ items: [] }));

    this.taskService
      .list({
        $page: 1,
        $limit: 999,
        start_date: {
          $gte: moment().startOf("week").format(),
          $lte: moment().endOf("week").format(),
        },
      })
      .subscribe((resp) => {
        this.store.dispatch(taskAll({ items: resp.data }));

        setTimeout(() => this.drawChart1(), 400);
      });
  }

  drawChart1() {
    const $startDate = moment().startOf("week");
    const $endDate = moment().endOf("week");

    const categories = [];
    const dataCompleted = [];
    const dataNew = [];
    const dataTotal = [];

    while ($startDate.isSameOrBefore($endDate)) {
      categories.push($startDate.format("MMM D"));

      const startOfMonth = moment($startDate.startOf("day").format());
      const endOfMonth = moment($startDate.endOf("day").format());

      let totalCompleted = 0;
      let totalNew = 0;
      let total = 0;

      this.allTasks.forEach((item) => {
        const startDate = moment(item.start_date);

        if (
          startDate.isSameOrAfter(startOfMonth) &&
          startDate.isSameOrBefore(endOfMonth) &&
          item.status === "completed"
        ) {
          totalCompleted++;
        }

        if (
          startDate.isSameOrAfter(startOfMonth) &&
          startDate.isSameOrBefore(endOfMonth) &&
          item.status === "new"
        ) {
          totalNew++;
        }
      });

      dataCompleted.push(totalCompleted);
      dataNew.push(totalNew);
      dataTotal.push(totalCompleted + totalNew);

      $startDate.add(1, "day");
    }

    Highcharts.chart(this.uuid1, {
      chart: {
        type: "column",
      },
      title: null,
      subtitle: null,
      xAxis: {
        categories: categories,
        crosshair: true,
        lineWidth: 0,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: null,
        // visible: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          borderRadius: "4px",
          stacking: "normal",
        },
      },
      series: [
        // {
        //   name: 'Total Tasks',
        //   data: dataTotal,
        //   showInLegend: false,
        // },
        {
          name: this.translate.instant("In Progress"),
          data: dataNew,
          color: "#E0E0E0",
        },
        {
          name: this.translate.instant("Completed"),
          data: dataCompleted,
          color: "#FFEB3B",
        },
      ],
    });
  }
}
