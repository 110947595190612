import { Component } from "@angular/core";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { MenuController, Platform } from "@ionic/angular";
import { AuthService } from "./services/auth/auth.service";
import { CommonService } from "./services/common/common.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [CommonService],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private commonService: CommonService,
    private menu: MenuController,
    private auth: AuthService
  ) {
    this.initializeApp();
  }

  openMenu() {
    this.menu.open("left");
  }

  isAccess() {
    return this.auth.isToken();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleBlackOpaque();
      setTimeout(() => this.splashScreen.hide(), 5000);

      // this.taskService.list({isDailyTask: true}).subscribe(resp => {
      //   for (let item of resp.data) {
      //     if (!item.isDailyTask) {
      //       continue;
      //     }

      //     console.log(moment(item.start_date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
      //     if (moment(item.start_date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      //       item.goals = item.goals || [];
      //       item.goalHistories = item.goalHistories || [];
      //       item.goalHistories.push({date: moment(item.start_date).format('YYYY-MM-DD'), goals: item.goals});

      //       item.start_date = moment().startOf('day').format();

      //       item.goals = item.goals.map((o: any) => {
      //         o.status = 'new';
      //         return o;
      //       });

      //       this.taskService.save(item).subscribe();
      //     }
      //   }
      // })
    });
  }
}
