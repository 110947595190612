import { createReducer, on } from "@ngrx/store";
import { routineCreate, routineDelete, routineList, routineUpdate } from "./routine.action";
import PERSONAL_HABITS from "../constants/PERSONAL_HABITS";

import {find} from "lodash";

export const INIT_STATE = {
  items: [],
};

export const reducerRoutine = createReducer(
  INIT_STATE,
  on(routineList, (state, { items }) => {

    const parsedItems = items.map(item => {
      const newItem = {...item};

      const type = find(PERSONAL_HABITS, o => item.type == o.id);
      console.log(type);

      if (type) {
        newItem.thumbnail = type.thumbnail;
      }

      return newItem;
    });

    return {
      ...state,
      items: [...parsedItems],
    };
  }),
  on(routineCreate, (state, { item }) => {
    return {
      ...state,
      items: [item, ...state.items],
    };
  }),
  on(routineUpdate, (state, { item }) => {
    let index = state.items.findIndex((o) => o.id === item.id);

    const items = [...state.items];

    if (index > -1) {
      items[index] = item;
    }

    return {
      ...state,
      items: items,
    };
  }),
  on(routineDelete, (state, { item }) => {
    let items = state.items.filter((o) => o.id !== item.id);

    return {
      ...state,
      items: items,
    };
  })
);
