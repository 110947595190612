import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authorizationHeaders: any = window.localStorage.getItem('accessToken') || 'DEFAULT_HEADER';

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authorizationHeaders}`)
    });

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}
