import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NavController } from "@ionic/angular";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    public http: HttpClient,
    private userService: UserService,
    private navCtrl: NavController
  ) {}

  login(model): any {
    const url = `${environment.apiUrl}/authentication`;
    return this.http.post(url, model);
  }

  verify(model): any {
    const url = `${environment.apiUrl}/users/verify`;
    return this.http.post(url, model);
  }

  resend(model): any {
    const url = `${environment.apiUrl}/users/verify-resend`;
    return this.http.post(url, model);
  }

  forgot(model): any {
    const url = `${environment.apiUrl}/users/forgot-request`;
    return this.http.post(url, model);
  }

  forgotVerify(model): any {
    const url = `${environment.apiUrl}/users/forgot-verify`;
    return this.http.post(url, model);
  }

  delete(model = {}): any {
    const url = `${environment.apiUrl}/users/delete-request`;
    return this.http.post(url, model);
  }

  deleteVerify(model): any {
    const url = `${environment.apiUrl}/users/delete-verify`;
    return this.http.post(url, model);
  }

  setAccessToken(resp) {
    const { accessToken, user } = resp;

    window.localStorage.setItem("accessToken", accessToken);
    this.setLoggedUser(user);
  }

  setLoggedUser(user) {
    window.localStorage.setItem("loggedUser", JSON.stringify(user));
  }

  signOut(): any {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("loggedUser");
  }

  getLoggedUser(key = null) {
    const loggedUser = window.localStorage.getItem("loggedUser");
    const result = loggedUser ? JSON.parse(loggedUser) : null;
    if (!result) {
      return null;
    }

    return key ? result[key] : result;
  }

  isToken() {
    return !!window.localStorage.getItem("accessToken");
  }

  checkAccess() {
    const accessToken = window.localStorage.getItem("accessToken");
    const loggedUser = this.getLoggedUser();

    if (!accessToken || !loggedUser) {
      this.signOut();
      this.navCtrl.navigateRoot(["login"], {replaceUrl: true});
      return;
    }

    this.userService.detail(loggedUser.id).subscribe(
      (resp) => window.localStorage.setItem("loggedUser", JSON.stringify(resp)),
      () => {
        this.signOut();
        this.navCtrl.navigateRoot(["login"], {replaceUrl: true});
      }
    );
  }
}
